<template>
  <div class="form">
    <el-form :inline="true" :model="search">
      <el-form-item>
        <el-input v-model.trim="search.name" class="account" clearable>
          <template slot="prepend">姓名</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="search.mobile" class="mobile" clearable>
          <template slot="prepend">手机号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model.trim="search.memo" class="memo" clearable>
          <template slot="prepend">消费项目</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model.trim="search.idCard" class="idCard" clearable>
          <template slot="prepend">身份证号</template>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          class="time"
          v-model="date"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          style="width:320px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: {
        name: '',
        mobile: '',
        idCard: ''
      },
      date: [],
      // 状态选项
      statusOpts: [
        { value: '', label: '请选择' },
        { value: 0, label: '充值成功' },
        { value: 1, label: '充值失败' }
      ]
    };
  },
  methods: {
    searchList() {
      let [startTime = '', endTime = ''] = this.date || [];
      let param = Object.assign({ startTime, endTime }, this.search);
      this.$emit('search', param);
    }
  }
};
</script>

<style lang="scss" scoped>
.form {
  text-align: left;
  .account.el-input {
    width: 160px;
  }
  .mobile.el-input {
    width: 220px;
  }
  .memo.el-input {
    width: 260px;
  }
  .idCard.el-input {
    width: 300px;
  }
  .el-select {
    width: 100px;
  }
}
@media screen and (max-width: 480px) {
.form .time{
  width: 100%;
}
}
</style>
<style>
@media screen and (max-width: 480px) {

.el-date-range-picker{
  width: 100%!important;
  left: 0!important;
}
.el-date-range-picker .el-picker-panel__body{
  width: 100%;
  min-width: 100%!important;
}
.el-date-range-picker__content .el-date-range-picker__header div{
  text-align: center;
    width: 100%;
    margin-left: 0!important;
    margin-right: 0!important;
}
}
</style>
