<template>
  <div class="account-table">
    <el-table
      border
      :data="copyList"
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '12px 0' }"
    >
      <!-- <el-table-column prop="tempId" label="ID" align="center"></el-table-column> -->
      <el-table-column prop="name" label="姓名" align="center" fixed="left"></el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center" min-width="125"></el-table-column>
      <el-table-column prop="idcard" label="身份证" align="center" min-width="170"></el-table-column>
      <el-table-column prop="created_at" label="查询时间" sortable align="center" min-width="165"></el-table-column>
      <el-table-column prop="product" label="消费项目" align="center" min-width="120"></el-table-column>
      <el-table-column label="消费金额" align="center">
        <template slot-scope="scope">{{ (scope.row.amount / 100).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="可用余额" align="center">
        <template slot-scope="scope">{{ (scope.row.amount_after / 100).toFixed(2) }}</template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    copyList() {
      let copy = JSON.parse(JSON.stringify(this.list));
      copy.forEach(item => {
        item.showPsd = false;
      });
      return copy;
    }
  },
  methods: {
    handleEdit(index, row) {
      this.$emit('show-edit', { index, row });
    },
    checkLock(index, row) {
      row.showPsd = !row.showPsd;
      this.copyList.splice(index, 1, row);
    }
  }
};
</script>

<style lang="scss" scoped>
.account-table {
  .psd-cell {
    width: 100%;
    position: relative;
    i {
      position: absolute;
      right: 0;
    }
  }
}

</style>